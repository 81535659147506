<template>
<div class="demo_container">
  <div class="header">
    <div class="left_logo">
      <img src="">
    </div>
    <div class="right_link">
      <div class="box_alink">
        <el-link style="color: #e9e9e9" src="">首页</el-link>
      </div>
      <div class="box_alink">
        <el-link style="color: #e9e9e9" src="">博客</el-link>
      </div>
      <div class="box_alink">
        <el-link style="color: #e9e9e9" src="">技术</el-link>
      </div>
      <div class="box_alink">
        <el-link style="color: #e9e9e9" src="">论坛</el-link>
      </div>
      <div class="box_alink">
        <el-link style="color: #e9e9e9" src="">关于</el-link>
      </div>
      <el-button @click="getDemo1">搜素</el-button>

    </div>
  </div>
  <div class="main">
    <div class="">
<!--  蔡老师.gif -->
      <el-image src="http://destiny001.gitee.io/image/cxk.gif"></el-image>
    </div>
  </div>
  <div class="footer"></div>

</div>
</template>

<script>
export default {
  name: "Demo1",
  data() {
    return {

    };
  },
  created() {
      this.getDemo1()
  },
  methods: {
      getDemo1() {

}
  }
}
</script>

<style lang="less" scoped>
.demo_container {

  height: 100%;

}

.header {
  display: flex;
  width: 100%;
  height: 50px;
  background: #CC5656;
}

.left_logo {
  width: 200px;
}

.right_link {
 width: 600px;
}

.box_alink {
  display: inline-flex;
  width: 30px;
  height: 20px;
  margin: 0 auto;
  padding: 20px;
  color: #ffffff;
  text-align: center;
}
</style>
